.contact-form__container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 10px;
}

.contact-form__holder {
  display: grid;
  grid-template-columns: 4fr 2fr;
  gap: 20px;
}

.contact-form__input {
  margin-bottom: 0px;
}

.contact-form__button {
  max-height: 50px;
}

@media (max-width: 430px) {
  .contact-form__holder {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}