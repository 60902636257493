.cookie__popup {
  z-index: 20;
  position: fixed;
  bottom: 50px;
  width: 100%;
  transition: all 0.2s ease 0s;
  opacity: 1;
  padding: 0px 10px;
}
.cookie__container {
  margin: 0 auto;
  display: flex;
  max-width: 800px;
  padding: 10px;
  background-color: #272a33;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
}
.cookie__text {
  color: #fff;
  font-size: 16px;
  margin: 0px;
  white-space: pre-wrap;
  padding-left: 20px;
}

.cookie__text a {
  color: rgba(255, 150, 174, 1);
}
/* .cookie__button {
  transition: all 0.2s ease 0s;
  margin-left: 100px;
  color: #fff;
  font-size: 16px;
  display: inline-flex;
  padding: 5px 15px;
  border: 2px solid #fff;
  border-radius: 5px;
  background-color: rgb(16, 118, 242);
}
.cookie__button:hover {
  background-color: rgb(130, 186, 255);
} */

.hide {
  opacity: 0;
  pointer-events: none;
  bottom: 45px;
}

@media (max-width: 770px) {
  .cookie__popup {
    bottom: 10px;
  }

  .hide {
    bottom: 5px;
  }
}

@media (max-width: 450px) {
  .cookie__container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cookie__text {
    padding-left: 0px;
    margin-bottom: 10px;
  }

  .cookie__button {
    width: 100%;
  }
}
